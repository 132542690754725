var generic = generic || {};

(function ($) {
  'use strict';

  Drupal.behaviors.basicTextBlockV1 = {
    attach: function (context) {
      $('.basic-text-block-v1', context).find('.overlay-toggle').once().click(function () {
        var content = $('.basic-text-block-v1', context).find('.text-block__overlay-content');

        if (content.length > 0) {
          generic.overlay.launch({
            content: content.clone(true),
            includeBackground: true,
            includeCloseLink: true,
            initialHeight: 0,
            height: 0,
            width: '50%',
            onComplete: function () {
              $(this).colorbox.resize();
            }
          });
        }

        return false;
      });
    }
  };
})(jQuery);
